<template>
  <div>
    <eden-page-header
      :title="'Customers'"
      :subtitle="name"
      :section="'Edit Profile'"
    />
    <customer-individual-form
      action="edit"
      :loading="loading"
      :customer="customer"
    />
  </div>
</template>

<script>
import CustomerIndividualForm from "@/components/Customers/Individual/CustomerIndividualForm";
import customer from "@/requests/customers/customer";
import * as actions from "@/store/action-types";

export default {
  name: "CustomerIndividualEdit",
  components: {
    CustomerIndividualForm,
  },
  data() {
    return {
      loading: false,
      customer: {
        avatar: "",
        first_name: "",
        last_name: "",
        email: "",
        phoneNo: "",
        birthday: "",
        address: "",
        access_note: "",
        landmark: "",
        location_area_id: "",
        gender: "",
        customer_persona: "",
        menu_type_variation_id: "",
      },
    };
  },
  computed: {
    customerId() {
      return this.$route.params.id;
    },
    name() {
      const name = this.customer.first_name + " " + this.customer.last_name;
      return this.formatName(name);
    },
  },
  created() {
    this.loading = true;
    this.$store.dispatch(actions.GET_LOCATION_AREAS_LIST);
    this.getCustomer();
  },
  methods: {
    getCustomer() {
      customer
        .profile(this.customerId)
        .then((response) => {
          console.log(response.data.data, "get customer");
          if (response.data.status) {
            const data = response.data.data;
            this.customer.avatar = data.avatar;
            this.customer.email = data.email;
            this.customer.first_name = data.name.split(" ")[0] ?? "";
            this.customer.last_name = data.name.split(" ")[1] ?? "";
            this.customer.phoneNo = data.phone_number ?? "";
            this.customer.birthday = data.birthday ?? "";
            this.customer.address = data.home_address ?? "";
            this.customer.landmark = data.landmark ?? "";
            this.customer.access_notes = data.accessnotes;
            this.customer.food_notes = data.foodnotes;
            this.customer.location_area_id = this.getLocationAreaIdFromName(
              data.location_area,
            );
            this.customer.gender = data.gender;
            this.customer.customer_persona =
              data.customer_persona_id && parseInt(data.customer_persona_id);
            this.customer.menu_type_variation_id = data.menu_type_variation_id;
            setTimeout(() => {
              this.loading = false;
            }, 500);
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    getLocationAreaIdFromName(locationName) {
      const locations = this.$store.getters.location_areas_list;

      const location = locations.filter((location) => {
        return location.name.toLowerCase() === locationName.toLowerCase();
      });

      return location.length ? location[0].id : null;
    },
  },
};
</script>

<style scoped lang="scss"></style>
